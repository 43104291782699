a.gflag {
  vertical-align: middle;
  font-size: 16px;
  padding: 1px 0;
  background-repeat: no-repeat;
  background-image: url(//gtranslate.net/flags/16.png);
}

a.gflag img {
  border: 0;
}

a.gflag:hover {
  background-image: url(//gtranslate.net/flags/16a.png);
}

#goog-gt-tt {
  display: none !important;
}

.goog-te-banner-frame {
  display: none !important;
}

.goog-te-menu-value:hover {
  text-decoration: none !important;
}

body {
  top: 0 !important;
}

#google_translate_element2 {
  display: none !important;
}

.gtranslate-container {
  position: fixed;
  bottom: 0;
  right: 0;
  border-top-left-radius: 10px;
  background: #4677d8;
  padding: 5px 10px;
  text-align: right;

  .hidden {
    display: none;
  }

  button {
    outline: 0;
    border: 0;
  }

  a {
    text-decoration: none;
  }

  .glink {
    display: block;
    padding: 5px 0;
    white-space: nowrap;
    width: 100%;

    img {
      vertical-align: middle;
      display: inline;
      border: 0;
      padding: 0;
      margin: 0;
      opacity: 0.8;
    }

    span {
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .gt_black_overlay {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 2017;
    opacity: .80;
  }

  .gt_white_content {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 341px;
    height: 375px;
    margin: -187.5px 0 0 -170.5px;
    padding: 6px 16px;
    border-radius: 5px;
    background-color: white;
    color: black;
    z-index: 19881205;
    overflow: auto;
    text-align: left;

    a {
      color: #000;
      display: block;
      padding: 5px px 0;
      border-bottom: 1px solid #e7e7e7;
      white-space: nowrap;

      &.selected {
        background-color: #ffc;
      }

    }

    .gt_languages {
      column-count: 1;
      column-gap: 10px;

      .glink {
        border-bottom: 1px solid #e7e7e7;
      }
    }
  }

  .switcher-popup span {
    color: #fff !important;
    font-family: 'Montserrat', 'Gotham SSm A', 'Gotham SSm B', 'Arial', sans-serif;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
  }
}