@import 'mixins';

// colors
$white: #fff;
$light-gray: #f2f2f2;
$black: #000;
$primary: #080133;
$light-blue: #99d8ff;
$gray-black: #0a0a0a;
$incorrect-red: #ea2c0c;
$correct-blue: #369ad8;
$dark-blue: #205C81;
$link-hover-blue: #86C2E7;
