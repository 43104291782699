.landing-page {
  .stage {
    background-color: $black;
    position: relative;

    .landing-hero {
      background-image: url('../../assets/images/home-hero-new-3.jpg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      position: relative;
      padding-bottom: 41.6%;

      @include desktop() {
        max-width: 120rem;
        height: 50rem;
        margin: 0 auto;
        padding-bottom: 0;
      }

      h1 {
        position: absolute;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
        width: 65%;
        height: auto;

        @include desktop() {
          width: 706px;
          height: 223px;
          bottom: -4px;
        }

        img {
          width: 100%;
        }
      }
    }

    .stage-content {
      text-align: center;
      padding: 4.5rem 4% 4.8rem 4%;

      @include desktop() {
        margin: 0 auto;
        padding: 4.5rem 0 4.8rem 0;
      }

      h1 {
        line-height: 0;

        span {
          font-size: 1.25rem;
          display: inline-block;
          margin-bottom: 3.5rem;
          text-transform: uppercase;
        }
      }

      img.landing-logo {
        width: 100%;
      }

      p {
        font-size: 1.8rem;
        line-height: 2.6rem;
        margin: 0 0 3.5rem 0;

        @include desktop() {
          font-size: 2.4rem;
          line-height: 3.2rem;
          width: 55%;
          margin: 0 auto 4rem auto;
        }
      }

      .landing-button {
        width: 90%;
        margin: 0 auto 4rem auto;
        display: inline-block;

        @include desktop() {
          width: 35%;
        }
      }

      a.standard-link {
        margin: 2rem 0;
      }
    }
  }
}