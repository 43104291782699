@import '../../sass/variables/variables';

.quiz-intro {
  min-height: 100vh;
  padding: 4rem 4%;
  background-image: url('../../assets/images/hero-intro-mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  @include desktop() {
    background-image: url('../../assets/images/hero-intro.jpg');
    background-size: cover;
  }
}

.intro-stage {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  @include desktop() {
    width: 66.66666667%;
  }
  h1 {
    margin-bottom: 3rem;
    @include desktop() {
      margin-bottom: 4.5rem;
    }
  }

  p {
    line-height: 1.3;
    font-size: 1.8rem;
    margin-bottom: 3rem;
    @include desktop() {
      font-size: 2.4rem;
      margin-bottom: 4rem;
    }
  }

  .button.intro {
    width: 95%;
    margin: 0 auto 3rem auto;
    @include desktop() {
      width: 65%;
    }
  }

}