.button {
  outline: none;
  text-align: center;
  height: 4rem;
  line-height: 4rem;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  font-family: inherit;
  display: block;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.6rem;
  transition: background-color .3s;

  @include desktop() {
    font-size: 2.4rem;
    height: 5.6rem;
    line-height: 5.6rem;
  }
  &.transparent {
    background-color: transparent;
    border: 1px solid #ddd;
  }

  &.opaque {
    background-color: rgba(0,0,0,0.3);
    border: 1px solid #636363;
  }

  &.light-blue {
    background-color: $light-blue;
    color: $primary;
    &:hover {
      background-color: #369ad8;
    }
    &:active {
      background-color: #105c8b;
    }
  }

  &.glow-blue {
    background-color: transparent;
    color: #fff;
    border: 2px solid #86c2e7;
    box-shadow: 0px 0px 6px 5px rgba(40,107,149,0.9);
    transition: all .3s;
    &:hover {
      border: 2px solid $white;
      box-shadow: 0px 0px 6px 5px rgba(257,257,257,0.9);
    }
    &:active {
      border: 2px solid $white;
      background-color: $correct-blue;
      color: $primary;
      box-shadow: none;
    }
  }

  &.solid-blue {
    background-color: #070331;
  }

  &.button-replay {
    background-color: $light-blue;
    color: $primary;
    font-weight: bold;
    &:hover {
      background-color: $white;
    }
    &:active {
      background-color: $correct-blue;
    }
  }
  &:focus {
    outline: revert;
  }
}
