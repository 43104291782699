body,
html {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
}

h1, h2, h3, h4 {
  font-weight: 700;
  text-transform: uppercase;
}

h1 {
  font-size: 4.8rem;
  @include desktop() {
    font-size: 5.6rem
  }
}

h2 {
  font-size: 3.6rem;
  @include desktop() {
    font-size: 4rem
  }
  &.large {
    font-size: 4.8rem;
    @include desktop() {
      font-size: 5.6rem
    }
  }
}

h3 {
  font-size: 3.2rem;
}

h4 {
  font-size: 2.8rem;
}

p,
a,
button,
li {
  font-size: 1.6rem;
}

p.quiz-question {
  font-size: 2.4rem;
  line-height: 1.4;
  margin-bottom: 3rem;
  @include desktop() {
    font-size: 4.8rem;
    margin-bottom: 6rem;
  }
}
a {
  cursor: pointer;
  &.standard-link {

    color: #fff;
    text-decoration: underline;
    text-transform: uppercase;
    display: block;
    font-weight: bold;
    transition: color .3s;
    &:hover {
      color: $link-hover-blue;
    }
    &:active {
      color: $correct-blue;
    }
  }
}
