.cta-box {
  background-color: #e1f0f9;
  padding: 4.5rem 2rem;
  text-align: center;
  h3 {
    color: #000;
    margin-bottom: 3rem;
  }
}

.cta-button-link {
  display: block;
  background-color: $primary;
  color: #86c2e7;
  height: 4rem;
  line-height: 4rem;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}
