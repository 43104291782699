.tabbed-content {
  .tabbed-content-hero {
    background-color: $primary;
    color: #fff;
    padding: 4rem 4% 4rem 4%;

    @include desktop() {
      padding: 7rem 0;
    }

    h1 {
      margin-bottom: 1.5rem;

      @include desktop() {
        font-size: 5.6rem;
      }
    }

    p {
      line-height: 1.4;
      font-size: 2rem;

      @include desktop() {
        width: 50%;
      }
    }
  }

  .tabs-container {
    background-color: #fff;
    padding: 6rem 0 0 0;
    border-bottom: 2px solid #369ad8;

    li {
      &.react-tabs__tab {
        display: inline-block;
        height: 4.6rem;
        text-transform: uppercase;
        line-height: 4.6rem;
        color: #6C6C6C;
        padding: 0 1.5rem;
        font-size: 1.7rem;
        font-weight: bold;
        margin-right: 1.5rem;
        transition: background-color .3s;
        cursor: pointer;

        @include desktop() {
          font-size: 1.8rem;
          padding: 0 3rem;
          margin-right: 3rem;
          height: 5.2rem;
          line-height: 5.2rem;
        }

        &--selected {
          background-color: #369ad8;
          color: #fff;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        &:hover {
          background-color: $dark-blue;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          color: #fff;
        }

        &:active {
          background-color: $primary;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          color: #fff;
        }
      }
    }
  }

  .tab-item {
    display: none;

    &.tab-active {
      display: block;
    }
  }

  .primary-message {
    background-color: #e1f0f9;
    padding: 4%;
    text-align: center;

    @include desktop() {
      padding: 4% 0;
    }

    h2 {
      line-height: 1.1;
      color: $primary;
      margin: 0 auto 3.5rem auto;

      @include desktop() {
        width: 50%;
      }
    }

    p {
      color: #000;
      margin: 0 auto 3.5rem auto;
      line-height: 1.4;
      font-size: 1.8rem;

      @include desktop() {
        width: 60%;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .item-title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 2.4rem;
  }
}

.promo-white {
  background-color: #fff;
  padding: 4rem 0;

  h2 {
    color: $primary;
    text-align: center;
    margin-bottom: 2rem;
  }

  p {
    color: #000;
    font-size: 1.8rem;
    line-height: 1.3;
  }

  ul {
    margin: 4rem 0 4rem 4rem;

    li {
      margin: 0 0 2rem 0;
      color: $primary;
      text-transform: uppercase;
      font-weight: bold;

      &:before {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin: .5rem 0 0 -4rem;
        background-color: #369ad8;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .cta-box {
    margin: 0 auto;

    @include desktop() {
      width: 75%;
    }

    .cta-button-link {
      @include desktop() {
        width: 75%;
      }

      margin: 0 auto 2rem auto;
    }
  }
}

.words-matter {
  .section-inner {
    .col-right {
      p {
        padding-left: 4rem;
      }
    }

    .header-centered {
      text-align: center;

      p {
        @include desktop() {
          width: 50%;
          margin: 0 auto;
        }
      }
    }
  }

  .section-inner.row {
    margin-bottom: 2rem;
    border-bottom: 1px solid $light-blue;
    padding-top: 4rem;
    padding-bottom: 4rem;
    padding-left: 0;
    padding-right: 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    h2 {
      margin-bottom: 4rem;
    }
  }

  ul {
    margin-left: 4rem;
    float: left;
    width: calc(50% - 4rem);

    @media screen and (min-width: 1100px) {
      //columns: 2;
    }
  }

  .wordlist-label {
    color: #ea2c0c !important;
    background-repeat: no-repeat;
    background-position: 0 50%;
    padding-left: 4rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-transform: uppercase;
    line-height: normal;

    &.incorrect {
      color: #ea2c0c !important;
      background-image: url('../../assets/images/icon-incorrect-old.svg');
    }

    &.correct {
      color: $correct-blue !important;
      background-image: url('../../assets/images/icon-correct.svg');
    }
  }

  li {
    font-size: 1.8rem;
    color: $primary;
    line-height: 1.3;
    margin-bottom: 2rem;
  }
}