.social-buttons {
  h2 {
    margin-bottom: 2.4rem;
    font-size: 2.4rem;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    li {
      display: inline-block;
      margin-right: 10px;
      button:not(:focus) {
        outline: 0;
      }
      a,
      span {
        width: 32px;
        height: 32px;
        cursor: pointer;
        display: block;
        background-repeat: no-repeat;
        font-size: 0px;
        text-indent: -9999px;
        &.social-facebook {
          background-image: url('../../assets/images/icon-footer-facebook.svg');
          &:hover {
            background-image: url('../../assets/images/icon-footer-facebook-hover.svg');
          }
          &:active {
            background-image: url('../../assets/images/icon-footer-facebook-active.svg');
          }
        }
        &.social-twitter {
          background-image: url('../../assets/images/icon-footer-twitter.svg');
          &:hover {
            background-image: url('../../assets/images/icon-footer-twitter-hover.svg');
          }
          &:active {
            background-image: url('../../assets/images/icon-footer-twitter-active.svg');
          }
        }
        &.social-instagram {
          background-image: url('../../assets/images/icon-instagram.svg');
          &:hover {
            background-image: url('../../assets/images/icon-footer-instagram-hover.svg');
          }
          &:active {
            background-image: url('../../assets/images/icon-footer-instagram-active.svg');
          }
        }
      }
    }
  }
}
