@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: 'Source Sans Pro', sans-serif !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color  !default;
$slick-prev-character: "\01F890" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url){
@if function-exists(image-url) {
  @return image-url($url);
}

@else {
  @return url($slick-loader-path + $url);
}
}

@function slick-font-url($url){
@if function-exists(font-url) {
  @return font-url($url);
}

@else {
  @return url($slick-font-path + $url);
}
}

/* Slider */

/* Icons */
@if $slick-font-family =="slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  height: 32px;
  width: 32px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: 2px solid $primary;
  border-radius: 32px;
  outline: none;
  transition: all .3s;
  display: none !important;

  @include desktop() {
    display: block !important;
  }

  &:hover,
  &:focus {
    border: 2px solid $white;
    outline: none;
    color: transparent;
  }

  &.slick-disabled,
  &.slick-disabled:before {
    opacity: .2;
    cursor: default;
  }
}

.slick-prev {
  left: -50px;
  background-image: url('../../assets/images/icon-arrow-left.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;

  &:hover,
  &:focus {
    background-image: url('../../assets/images/icon-arrow-left-hover.svg');
  }
}

.slick-next {
  right: -50px;
  background-image: url('../../assets/images/icon-arrow-right.svg');
  background-repeat: no-repeat;
  background-position: 50% 50%;

  &:hover,
  &:focus {
    background-image: url('../../assets/images/icon-arrow-right-hover.svg');
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    height: 14px;
    width: 14px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    &.slick-active {
      button {
        background: $white;
      }
    }

    button {
      border-radius: 14px;
      background: $primary;
      display: block;
      height: 14px;
      width: 14px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      transition: border .3s;
      cursor: pointer;

      &:hover,
      &:focus {
        background: $white;
        outline: none;
      }

    }
  }
}