@import '../../sass/variables/variables';

.page-not-found {
  background-color: $primary;
  height: 100vh;
  text-align: center;
  padding: 10% 0;
  h2 {
    color: $white;
  }
  .button {
    width: 33%;
    margin: 3rem auto 0 auto;
  }
}