@import '../../sass/variables/variables';

.quiz-container {
  background-color: $primary;
  min-height: 100vh;
  transition: all .3s;
  &.incorrect {
    background-color: $primary;
  }

  .quiz-stage {
    margin: 0 auto;
    text-align: center;
    padding: 3% 4%;
    &.timer-off {
      padding-top: 5rem;
    }
    @include desktop() {
      width: 75%;
      padding: 3% 0 0 0;
    }
    .quiz-stage-inner {
      &:focus {
        outline: none;
      }
    }
    span {
      display: inline-block;
      font-size: 1.8rem;
      margin-bottom: 1rem;
      @include desktop() {
        margin-bottom: 1.5rem;
      }
    }
    h2 {
      font-weight: normal;
      letter-spacing: 1px;
      line-height: 1.2;
      margin-bottom: 1.25rem;
    }
    h3 {
      color: $light-blue;
      &.incorrect {
        color: #ea2c0c;
      }
    }
    .question-number, .question-status {
      display: block;
      color: #99d8ff;
      margin-bottom: 2rem;
      transition: color .3s;
      font-size: 3.2rem;
      font-weight: 700;
      &.incorrect {
        color: #ea2c0c;
      }
    }
    .fillin-line {
      display: inline-block;
      border-bottom: 2px solid #fff;
      width: 150px;
      margin-bottom: 0;
    }
    .skip-question,
    .next-button {
      display: inline-block;
      margin-top: 4rem;
      cursor: pointer;
      text-transform: uppercase;
      text-decoration: underline;
      font-size: 1.8rem;
      margin-bottom: 1rem;
      transition: all .3s;
      &:hover {
        color: $link-hover-blue;
      }
      &:active {
        color: $correct-blue;
      }
      @include desktop() {
        margin-bottom: 1.5rem;
      }
      &.paused {
        cursor: default;
        opacity: .3;
      }
    }
    .next-button {
      margin-left: 1rem;
    }
  }
}

.answer-container {
  display: flex;
  flex-direction: column;
  @include desktop() {
    flex-wrap: wrap;
    justify-content:space-around;
    flex-direction: row;
  }
  .answer-button {
    flex-grow: 1;
    color: #fff;
    background-color: transparent;
    border: 2px solid #86c2e7;
    box-shadow: 0px 0px 6px 5px rgba(40,107,149,0.9);
    font-size: 2rem;
    text-align: center;
    cursor: pointer;
    transition: background-image .3s;
    padding: 2.5% 8.5%;
    margin-bottom: 2.5%;
    position: relative;
    &:focus {
      outline: 1px solid white;
    }
    @include desktop() {
      width: 46%;
      margin-right: 4%;
      padding: 2.5% 5%;
    }
    @media (hover: hover) {
      &:hover {
        box-shadow: 0px 0px 6px 5px rgba(257,257,257,0.9);
        border: 2px solid $white;
      }
    }
    &.incorrect {
      box-shadow: none;
      color: $primary;
      background-color: $incorrect-red;
      border: 2px solid $incorrect-red;
      background-image: url('../../assets/images/icon-incorrect.svg');
      background-repeat: no-repeat;
      background-position: 2% 50%;
      @media (hover: hover) {
        &:hover {
          color: $primary;
          background-color: $incorrect-red;
        }
      }
    }
    &.correct {
      box-shadow: none;
      color: #080133;
      background-color: $correct-blue;
      border: 2px solid $correct-blue;
      background-image: url('../../assets/images/icon-correct-quiz.svg');
      background-repeat: no-repeat;
      background-position: 2% 50%;
      @media (hover: hover) {
        &:hover {
          background-color: $correct-blue;
          color: #080133;
        }
      }
    }
    &.neutral {
      box-shadow: none;
      border: 2px solid #a9a9a9;
      color: #a9a9a9;
      @media (hover: hover) {
        &:hover {
          background-color: transparent;
          color: #a9a9a9;
        }
      }
    }
  }
}

