@import '../../sass/variables/variables';

.privacy-policy {
  background: $white;
  h1, h2, h3 {
    color: $primary;
  }
  p, address, a, li {
    color: #0c0c0c;
    line-height: 1.4;
    margin-bottom: 4rem;
    font-size: 1.8rem;
  }

  a, li {
    color: #205c81;
  }

  li {
    margin-bottom: 2.5rem;
  }
  a {
    text-transform: uppercase;
  }
  section,
  .section {
    &.primary-section {
      background-color: #e1f0f9;
      .centered {
        width: 100%;
        text-align: center;
      }
      .section-inner {
        border-bottom: none;
        h2 {
          text-align: center;
          margin-bottom: 1rem;
          padding-bottom: 0;
          &:after {
            display: none;
          }
        }
      }
    }
    .section-inner {
      padding: 6.5rem 4%;
      border-bottom: 1px solid #99cceb;
      width: 92%;
      margin: 0 auto;
      @include desktop() {
        width: 75%;
      }
      .call-out {
        background-color: #f2f2f2;
        padding: 6% 4%;
        margin: 0 0 4rem 0;
        @include desktop() {
          margin: 0 -4% 4rem -4%;
        }
      }
      h2, h3 {
        margin-bottom: 4rem;
      }
      h2 {
        position: relative;
        padding-bottom: 3.5rem;
        &:after{
          content: '';
          left: 0;
          bottom: 0;
          position: absolute;
          width: 4.5rem;
          height: 1rem;
          background-color: #ea2c0c;
        }
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
      ul {
        list-style-type: disc;
        margin: 0 0 4rem 2rem;
      }
    }
  }
}