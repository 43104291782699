body {
  background: #000;
  margin: 0;
}

.quiz-app {
  width: 100%;
  color: #fff;

  .section, section {
    &.centered {
      text-align: center;
    }

    &.padded {
      padding: 7rem 0;
    }

    &.bg-white {
      background-color: $white;
      h1, h2, h3, h4 {
        color: $primary;
      }
    }

    &.bg-gray {
      background-color: #f2f2f2;
      h1, h2, h3, h4 {
        color: $primary;
      }
    }

    &.bg-primary {
      background-color: $primary;
      h1, h2, h3, h4, p {
        color: $white;
      }
    }
    .section-inner {
      margin: 0 auto;
      @include desktop() {
        max-width: 120rem;
        padding: 0 4rem;
      }
      &.border-blue {
        border-top: 1px solid $light-blue;
        padding: 4.5rem 4%;
        @include desktop() {
          padding: 7rem 0 10rem 0;
        }
        h2 {
          margin-bottom: 2.5rem;
          @include desktop() {
            margin-bottom: 4rem;
          }
        }
        .button {
          width: 50%;
          margin: 0 auto;
        }
      }
      &.narrow {
        @include desktop() {
          width: 65%;
          max-width: 65%;
        }
        .content {
          padding: 0 4%;
          @include desktop() {
            padding: 0;
          }
        }
      }
    }
  }

  .two-column-content {
    padding: 6%;
    .section-inner {
      display: flex;
      flex-direction: column;
      &.span-10 {
        @include desktop() {
          width: 75%;
          margin: 0 auto;
        }
      }
      @include desktop() {
        flex-direction: row;
      }
      .column {
        width: 100%;
        @include desktop() {
          width: 50%;
          flex-basis: 50%;
        }
      }
      .column-span-5 {
        width: 100%;
        @include desktop() {
          flex-basis: 41.66666667%;
          width: 41.66666667%;
        }
        &.shift-1 {
          @include desktop() {
            margin-left: 8.33333333%; 
          }  
        }
      }
      .col-left {
        padding-bottom: 4rem;
        @include desktop() {
          padding: 0 8rem 0 0;
        }
      }
    }
    h3,
    h4 {
      color: $primary;
      font-size: 4rem;
      font-weight: bold;
      margin-bottom: 2rem;
    }
    h4 {
      font-size: 2rem;
    }
    p {
      font-size: 2rem;
      color: #000;
      line-height: 1.3;
    }

  }

  .three-column {
    background-color: #fff;
    padding: 3.5rem 2rem;
    color: $primary;
    @include desktop() {
      padding: 7.5rem;
    }

    .section-inner {
      display: flex;
      flex-wrap: wrap;
    }

    .three-column-item {
      width: 100%;
      padding: 0 0 4rem 0;
      p {
        color: $primary;
        font-weight: bold;
        font-size: 2.4rem;
        text-transform: uppercase;
        text-align: center;
      }
      @include tablet() {
        width: 50%;
        flex-basis: 50%;
        padding: 0 4rem 4rem 4rem;
      }
      @include desktop() {
        width: 33.3333%;
        flex-basis: 33.3333%;
        padding: 0 6rem 6rem 6rem;
      }
    }

    h2 {
      margin-bottom: 4.5rem;
    }

    a {
      color: #205C81;
      text-transform: uppercase;
      font-size: 1.6rem;
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .promo {
    padding: 8rem 0;
    text-align: center;
    background-color: $primary;
    h2 {
      margin-bottom: 4rem;
    }

    p {
      width: 75%;
      margin: 0 auto 4rem auto;
      @include desktop() {
        width: 50%;
      }
    }
    .button {
      width: 95%;
      margin: 0 auto;
      display: inline-block;
      padding: 0 1%;
      font-size: 1.4rem;
      @include desktop() {
        width: auto;
        padding: 0 2%;
        font-size: 2.4rem;
        //max-width: 60%;
      }
    }
  }

}

