header {
  width: 100%;
  display: flex;
  align-items: center;
  height: 5rem;
  padding: 0 4%;
  position: relative;
  @include desktop() {
    padding: 0;
    height: 10rem;
  }
  .logo {
    width: 91px;
    height: 28px;
    @include desktop() {
      width: 120px;
      height: 36px;
    }
    img {
      width: 100%;
    }
  }

  .nav-links {
    margin-left: auto;
    justify-content: flex-end;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: height 0ms .3s, opacity .3s 0ms;
    @include desktop() {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
    &.mobile-nav-open {
      position: fixed;
      z-index: 1;
      background-color: rgba(0, 0, 0, .9);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      opacity: 1;
      height: auto;
      ul {
        border-top: 1px solid $correct-blue;
        margin: 5rem auto 0 auto;
        width: 92%;
        flex-direction: column;
        li {
          width: 100%;
          margin: 0;
          border-bottom: 1px solid $correct-blue;
          padding: 2.5rem 0
        }
      }
    }
    @include desktop() {
      display: block;
      height: auto;
    }
  }

  .mobile-nav-toggle-close, .mobile-nav-toggle-open {
    background-color: transparent;
    padding: 0;
    outline: none;
    border: none;
    &:focus {
      outline: 1px solid $light-blue;
    }
  }
  .mobile-nav-toggle-open {
    display: block;
    width: 24px;
    height: 34px;
    background-image: url('../../assets/images/icon-mobile-nav-open.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4%;
    cursor: pointer;
    @include desktop() {
      display: none;
    }
  }
  .mobile-nav-toggle-close {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('../../assets/images/icon-mobile-nav-close.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
    @include desktop() {
      display: none;
    }
  }
  ul {
    display: flex;
    width: 100%;
    align-self: flex-end;
    li {
      margin-right:70px;
      width: auto;
      &:last-child {
        margin-right: 0;
      }
      a {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: bold;
        transition: color .3s;
        @include desktop() {
          display: block;
          height: 10rem;
          line-height: 10rem;
        }

        &:hover {
          color: $link-hover-blue;
        }
        &:active,
        &.active {
          color: $correct-blue;;
          @include desktop() {
            color: $white;
            border-bottom: 2px solid $correct-blue;
          }
        }
      }
    }
  }

  .inner-header {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    @include desktop() {
      padding: 0 4rem;
    }
  }
}
.skip-link {
  position: absolute;
  z-index: 9999;
  display: block;
  top:0;
  width: 100%;
  text-align: center;
  color: white;
}
