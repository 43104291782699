@import '../../sass/variables/variables';

.more-info {
  background-color: #fff;
  @include desktop() {
    //padding: 0 4%;
  }

  &.alternate-layout {
    .section-inner-wide {
      background-color: #f2f2f2;
      @include desktop() {
        .info-container {
          flex-direction: row-reverse;
          .info-item {
            margin-left: 0;
          }
          .pull-quote-box {
            margin-left: 8.33333333%; 
          }
        }
      }
    }
  }

  .section-inner-wide {
    width: 100%;
    padding: 5rem 0;
    @include desktop() {
      padding: 10rem 0;
    }    
    &:nth-child(even) {
      background-color: #f2f2f2;
      @include desktop() {
        .info-container {
          flex-direction: row-reverse;
          .info-item {
            margin-left: 0;
          }
          .pull-quote-box {
            margin-left: 8.33333333%; 
          }
        }
      }
    }
  }

  .info-container {
    display: flex;    
    margin: 0 auto;
    flex-direction: column;
    @include desktop() {
      max-width: 1200px;
      justify-content: left;
      align-items: flex-start;
      flex-direction: row;
    }
  }
  .pull-quote-box {
    background-color: $primary;
    text-align: center;
    padding: 6%;
    text-transform: uppercase;
    width: 95%;
    margin: 0 auto;
    @include desktop() {
      width: 41.66666667%;
    }

    &.icon {
      padding: 28% 6% 6% 6%;
      background-position: 50% 8%;
      background-repeat: no-repeat;
      @include desktop() {
        padding: 12% 6% 6% 6%;
        background-position: 50% 12%;
      }
      &.icon-comfort {
        background-image: url('../../assets/images/icon-comfort.svg');
      }
      &.icon-crowd {
        background-image: url('../../assets/images/icon-crowd.svg');
      }
      &.icon-medical {
        background-image: url('../../assets/images/icon-medical.svg');
      }
      &.icon-selfcare {
        background-image: url('../../assets/images/icon-self-care.svg');
      }
    }

    p {
      color: #86c2e7;
      font-size: 1.8rem;
      font-weight: bold;
      @include desktop() {
        font-size: 2.4rem; 
      }
      span {
        display: block;
        color: #fff;
        font-size: 3.2rem;
        span {
          font-size: 3.6rem;
        }
        margin: 2% 0;
        @include desktop() {
          font-size: 4rem;
          font-weight: bold;
          span {
            font-size: 5.6rem;
          }
        }
      }
    }

    a.button-more-info {
      display: block;
      background-color: $light-blue;
      height: 4rem;
      line-height: 4rem;
      color: $primary;
      font-weight: bold;
      margin-top: 1.5rem;
      transition: all .3s;
      &:hover {
        background-color: #369ad8;
      }
      &:active {
        background-color: #105c8b;
      }
    }
  }
  .info-item {
    width: 100%;
    //margin-right: 4rem;
    padding: 0 4%;
    @include desktop() {
      width: 41.66666667%;;
      margin-left: 8.33333333%;
      padding: 0;
    }
    p {
      color: #363636;
      font-size: 1.8rem;
      line-height: 1.5;
      margin: 0 0 2rem 0;
    }

    h2 {
      line-height: 1.1;
      color: $primary;
      margin-bottom: 3.2rem;
    }
    h4 {
      line-height: 1.1;
      font-size: 2.4rem;
      color: $primary;
      margin: 4rem 0 2.5rem;
    }

    a {
      color: #205C81;
      text-decoration: underline;
      text-transform: uppercase;
      font-weight: bold;
      display: inline-block;
      margin-bottom: 4rem;
      &:hover {
        color: $link-hover-blue;
      }
      &:active {
        color: $correct-blue;
      }
    }
    hr {
      outline: 0;
      border: none;
      display: block;
      height: 1rem;
      background-color: #ea2c0c;
      width: 4.5rem;
      margin: 0 0 3.2rem 0;
    }

  }
}