@import '../../sass/variables/variables';

.two-column-promo {
  background-color: $primary;
  text-transform: uppercase;
  text-align: center;
  padding: 4% 0;

  h2,
  h4,
  a {
    color: $white;
  }

  h2 {
    margin-bottom: 2.5rem;
  }

  h4 {
    margin-bottom: 2rem;
  }

  .promos {
    display: flex;
    flex-direction: column;

    @include desktop() {
      flex-direction: row;
    }

    .promo-box {
      padding: 5% 2% 0 2%;
      color: #080133;

      &:first-child {
        margin: 0 0 2% 0;
      }

      @include desktop() {
        width: 49%;

        &:first-child {
          margin: 0 2% 0 0;
        }
      }
    }

    a {
      display: block;
      font-weight: bold;
      background-color: $light-blue;
      color: $primary;
      text-align: center;
      margin: 0 auto 2rem auto;
      height: 4rem;
      line-height: 4rem;
      width: 90%;
      transition: all .3s;
      font-size: 1.6rem;

      &:hover {
        background-color: #369ad8;
      }

      &:active {
        background-color: #105c8b;
      }

      @include desktop() {
        width: 100%;
        font-size: 2.4rem;
        height: 5.6rem;
        line-height: 5.6rem;
      }
    }
  }

  &.bg-white {
    .section-inner {
      border-top: 1px solid $light-blue;
      padding: 3rem 0;

      @include desktop() {
        padding: 6rem 0;
        width: 68%;
        margin: 0 auto;
      }
    }

    .promos {
      .promo-box {
        padding: 0 2% 4rem 2%;
        color: #080133;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
          margin-bottom: 2rem;
        }

        &:first-child {
          margin: 0 0 2% 0;
        }

        @include desktop() {
          width: 49%;

          &:first-child {
            margin: 0 2% 0 0;
          }
        }

        .button {
          @media screen and (max-width: 1340px) and (min-width: 1024px) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
}