@import '../../sass/variables/variables';

footer {
  height: 300px;
  background-color: #000;
  background-image: url('../../assets/images/OOEA_logo.svg');
  background-repeat: no-repeat;
  background-position: 50% 15%;
  position: relative;  
  hr {
    outline: 0;
    border: none;
    display: block;
    height: 2px;
    background-color: #444;
    width: 20%;
    position: absolute;
    top: 25%;
    opacity: .5;
    @include desktop() {
      width: 30%;
    }
    &.hr-left {
      left: 10%;
    }
    &.hr-right {
      right: 10%;
    }
  }
  .footer-inner {
    position: relative;
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    height: 300px;
    text-align: center;
    .footer-links {
      position: absolute;
      bottom: 12%;
      width: 100%;
      text-align: center;
      a.standard-link {
        display: inline-block !important;
      }
      .pipe {
        display: inline-block;
        margin: 0 1rem;
        font-size: 1rem;
        font-weight: 700;
        color: #fff;
      }
    }
    ul {
      position: absolute;
      bottom: 25%;
      left: 50%;
      transform: translateX(-50%);
      width: 168px;
      li {
        display: inline-block;
        margin-right: 10px;
        a {
          width: 32px;
          height: 32px;
          cursor: pointer;
          display: block;
          background-repeat: no-repeat;
          font-size: 0px;
          transition: all .3s;
          &.social-facebook {
            background-image: url('../../assets/images/icon-footer-facebook.svg');
            &:hover {
              background-image: url('../../assets/images/icon-footer-facebook-hover.svg');
            }
            &:active {
              background-image: url('../../assets/images/icon-footer-facebook-active.svg');
            }

          }
          &.social-twitter {
            background-image: url('../../assets/images/icon-footer-twitter.svg');
            &:hover {
              background-image: url('../../assets/images/icon-footer-twitter-hover.svg');
            }
            &:active {
              background-image: url('../../assets/images/icon-footer-twitter-active.svg');
            }
          }
          &.social-instagram {
            background-image: url('../../assets/images/icon-footer-instagram.svg');
            &:hover {
              background-image: url('../../assets/images/icon-footer-instagram-hover.svg');
            }
            &:active {
              background-image: url('../../assets/images/icon-footer-instagram-active.svg');
            }
          }
          &.social-youtube {
            background-image: url('../../assets/images/icon-footer-youtube.svg');
            &:hover {
              background-image: url('../../assets/images/icon-footer-youtube-hover.svg');
            }
            &:active {
              background-image: url('../../assets/images/icon-footer-youtube-active.svg');
            }
          }
        }
      }
    }
  }
}