@import '../../sass/variables/variables';

.about-us {
  color: $primary;
  background-color: $white;
  padding: 4%;

  @include desktop() {
    padding: 7rem 0 0 0;
  }

  h2 {
    margin-bottom: 3rem;

    @include desktop() {
      margin-bottom: 7rem;
    }
  }

  .three-column-row {
    display: flex;
    flex-direction: column;

    @include desktop() {
      flex-direction: row;
      margin-bottom: 4rem;
      padding-bottom: 7rem;
    }

    &.border-bottom {
      border-bottom: 1px solid $correct-blue;
      margin-bottom: 4rem;
    }

    .column {
      margin-bottom: 3rem;

      @include desktop() {
        width: 33.3%;
        flex-basis: 33.3%;
        margin-bottom: 0;
      }

      p.item-title {
        margin-bottom: 2rem;
      }

      a {
        color: $dark-blue;
        text-transform: uppercase;
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
}