.game-timer-container {
  width: 100%;
  background-color: #1e1e1e;
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 0 4%;
  @include desktop() {
    padding: 0;
  }
  &.paused {
    display: none;
  }
  .timer-inner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    @include desktop() {
      padding: 0 4rem;
    }
    p {
      margin-left: auto;
    }
  }
  .progress-bar {
    background-color: #666;
    width: 60%;
    height: .4rem;
    width: 90%;
    .progress-bar-inner {
      height: .4rem;
      background-color: #a2f1fc;
      transition: width .5s ease;
    }
  }
}

.answer-bar {
  width: 100%;
  height: 5rem;
  text-align: center;
  font-size: 3.5rem;
  line-height: 5rem;
  &.answer-correct {
    background-color: #4dcdff;
    color: $primary; 
    text-transform: uppercase;
    font-weight: bold;
  }
  &.answer-incorrect {
    background-color: #ea2c0c;
    color: $primary; 
    text-transform: uppercase;
    font-weight: bold;
  }
  &.answer-skipped {
    background-color: #a9a9a9;
    color: $primary; 
    text-transform: uppercase;
    font-weight: bold;
  }
  &.answer-placeholder {
    background-color: transparent;
    &.paused {
      display: none;
    }
  }
}
