@import '../../sass/variables/variables';

.results-container {
  .fillin-line {
    display: inline-block !important;
    border-bottom: 2px solid #000;
    width: 50px;
  }
  .results-main {
    background-image: url('../../assets/images/background-results-high-tier-mobile.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #1d5374;
    text-align: center;
    padding: 4.8rem 4%;
    @include desktop() {
      padding: 4.8rem 0;
      background-image: url('../../assets/images/background-results-hight-tier.png');
    }
    &.low-score {
      background-image: url('../../assets/images/background-results-low-tier-mobile.png');
      background-color: #7f1706;
      @include desktop() {
        background-image: url('../../assets/images/background-results-low-tier.png');
      }
    }
    .results-inner {
      margin: 0 auto;
      @include desktop() {
        width: 58.3%;
        max-width: 700px;
      }
    }
    span {
      color: #fff;
      font-size: 1.6rem;
      display: inline-block;
      margin-bottom: 2.4rem;
    }
    h1 {
      color: #fff;
      margin-bottom: 2.4rem;
      line-height: 1;
    }

    p {
      color: #fff;
      margin: 0 auto 4rem auto;
      line-height: 1.5;
      @include desktop() {
        width: 75%;
      }
    }

    a.standard-link {
      display: inline-block;
      margin: 0 0 8.8rem 0;
    }

    .button {
      width: 100%;
      margin: 0 auto 3.2rem auto;
      @include desktop() {
        width: 50%;
      }
    }
  }

  .results-answers {
    background-color: $light-gray;
    text-align: center;
    padding: 3% 0;
    h2 {
      color: #080133;
      margin-bottom: 3rem;
    }
    .user-answer {
      text-align: left;
      margin: 0 auto 3rem auto;
      background-color: $white;
      color: #000;
      padding: 3%;
      @include desktop() {
        width: 90%;
      }
      h4, .result-label {
        font-size: 1.6rem;
        font-weight: bold;
        margin: 1.5rem 0;
        text-transform: uppercase;
        @include desktop() {
          margin-top: 0;
        }
      }
      span {
        font-size: 1.7rem;
        font-weight: bold;
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-size: 18px 18px;
        display: inline-block;
        height: 18px;
        line-height: 18px;
        padding-left: 28px;
        &.correct {
          color: #369ad8; // ea2c0c
          background-image: url('../../assets/images/icon-correct.svg');
        }
        &.incorrect {
          color: #ea2c0c;
          background-image: url('../../assets/images/icon-incorrect-old.svg');
        }
      }
      .question-answer-container {
        display: flex;
        margin-bottom: 2rem;
        flex-direction: column;
        @include desktop() {
          flex-direction: row;
        }
        .question-answer {
          @include desktop() {
            width: 60%;
          }

        }
        .your-answer {
          @include desktop() {
            width: 40%;
            text-align: right;
          }

          p:not(.result-label) {
            margin: 1.5rem 0;
            @include desktop() {
              margin: 0;
            }
          }
        }
      }
      .correct-answer {
        p:not(.result-label) {
          margin: 1.5rem 0;
          @include desktop() {
            width: 75%;
          }
        }

        a {
          text-transform: uppercase;
          color: $dark-blue;
          font-weight: bold;
          text-decoration: underline;
          &:hover {
            color: $link-hover-blue;
          }
          &:active {
            color: $correct-blue;
          }
        }
      }
    }
  }
}
